/*
 * Contains the button to switch between the therapists own patients and the clinics patients.
 */

let allPatientsStatus = false;

/*
 * Searches trough the patient search table and hides entries that do not correspond to the query put in.
 */
function tableSearchFunction() {
    let td;
    const input = document.getElementById("patientInput");
    const filter = input.value.toUpperCase();
    const table = document.getElementById("patientTable");
    const tr = table.getElementsByTagName("tr");
    const th = table.getElementsByTagName("th");

    for (let i = 1; i < tr.length; i += 1) {
        tr[i].style.display = "none";
        for (let j = 0; j < th.length; j += 1) {
            td = tr[i].getElementsByTagName("td")[j];
            if (td) {
                if (td.innerHTML.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
                    tr[i].style.display = "";
                    break;
                }
            }
        }
    }
}
window.tableSearchFunction = tableSearchFunction;

function allPatientsSwitch(inputPatientSwitch) {
    const status = $(inputPatientSwitch).prop("checked");

    if (allPatientsStatus !== status) {
        allPatientsStatus = status;

        // const clinicTable = $("#clinicTable");
        // $("#patientTable").prop("id", "clinicTable");
        // clinicTable.prop("id", "patientTable");

        if (status) {
            $("#checkbox-label").text(trans.forms["entire-clinic"]);
        } else {
            $("#checkbox-label").text(trans.forms["your-patients"]);
        }

        if (status) {
            $(".patient-clinic-row").removeClass("d-none");
            $(".patient-row").addClass("d-none");
        } else {
            $(".patient-clinic-row").addClass("d-none");
            $(".patient-row").removeClass("d-none");
        }

        tableSearchFunction();
    }
}

window.allPatientsSwitch = allPatientsSwitch;

function patientProblemsMessagesSwitch(inputPatientSwitch) {
    const status = $(inputPatientSwitch).prop("checked");

    if (status) {
        $("#patient-table-problems").removeClass("d-none");
        $("#patient-table-messages").addClass("d-none");
    } else {
        $("#patient-table-problems").addClass("d-none");
        $("#patient-table-messages").removeClass("d-none");
    }
}

window.patientProblemsMessagesSwitch = patientProblemsMessagesSwitch;

/*
 * When the DOM with all its elements are loaded.
 */
$(function () {
    $("#all-patients2")
        .off()
        .on("change", () => {
            // console.log(event);
            // var error = new Error();
            // console.log(error.stack)
            // var status = $(this).prop("checked");
            //
            // if (allPatientsStatus != status) {
            //     allPatientsStatus = status;
            //
            //     var clinicTable = $("#clinicTable");
            //     $("#patientTable").prop("id", "clinicTable");
            //     clinicTable.prop("id", "patientTable");
            //
            //     if (status) {
            //         $(this).siblings("#checkbox-label").text(trans.forms['entire-clinic'])
            //     } else {
            //         $(this).siblings("#checkbox-label").text(trans.forms['your-patients'])
            //     }
            //
            //     // console.log($(this).siblings("#checkbox-label"))
            //
            //     if (status) {
            //         $("#clinicTable-entire").removeClass("hidden")
            //         $("#patientTable-entire").addClass("hidden")
            //     } else {
            //         $("#clinicTable-entire").addClass("hidden")
            //         $("#patientTable-entire").removeClass("hidden")
            //     }
            //     tableSearchFunction()
            // }
        });
});
